import { Component } from 'react';
import { APP_EVENTS } from '../../constants';
import { appEventEmitter } from '../../initialization';
import { preventMouseEvent } from '../../utils';
import { OverlayComponent } from './overlay.component';
import  { CloseSquareOutlined } from '@ant-design/icons';  

export interface ConfirmModalData {
  title?: React.ReactNode;
  description?: React.ReactNode;
  onOK?: () => void;
  onCancel?: () => void;
  hideOK?: boolean;
  hideCancel?: boolean;
  okText?: string;
  cancelText?: string;
}

const resetState = {
  onCancel: undefined,
  onOK: undefined,
  hideOK: false,
  hideCancel: false,
  okText: 'Yes',
  cancelText: 'No',
  description: '',
}

export class ConfirmModalComponent extends Component<
  any,
  ConfirmModalData & { status: boolean }
> {
  offListener: (() => void) | undefined;

  constructor(props: any) {
    super(props);
    this.state = {
      title: 'Do your confirm?',
      status: false,
      hideOK: false,
      hideCancel: false,
      okText: 'Yes',
      cancelText: 'No',
    };
  }

  componentDidMount() {
    this.offListener = appEventEmitter.on(
      APP_EVENTS.app_confirm_modal,
      (data: ConfirmModalData) =>
        data.title && this.setState({ ...data, status: true })
    );
  }

  componentWillUnmount() {
    this.offListener && this.offListener();
  }

  onOK() {
    this.state.onOK && this.state.onOK();
    this.setState({
      status: false,
      title: '',
      ...resetState
    });
  }

  onCancel() {
    this.state.onCancel && this.state.onCancel();
    this.setState({
      status: false,
      title: '',
      ...resetState
    });
  }

  onClose() {
    this.setState({
      status: false,
      title: '',
      ...resetState
    });
  }

  render() {
    return (
      <OverlayComponent display={this.state.status}>
        <div className="confirm-modal" onClick={preventMouseEvent(this.onClose.bind(this))} >
          <div className="confirm-modal-main" onClick={(e) => e.stopPropagation()} >
            <CloseSquareOutlined 
              className='confirm-modal-close'
              style={{fontSize: '18px'}}
              onClick={preventMouseEvent(this.onClose.bind(this))}
            />
            <span className="confirm-modal-message">{this.state.title}</span>
            <p className="confirm-modal-des">{this.state.description}</p>
            <div className="confirm-modal-btn">
              {!this.state.hideOK &&
              <button
                onClick={preventMouseEvent(this.onOK.bind(this))}
                className="ok"
              >
                {this.state.okText}
              </button>}
              {!this.state.hideCancel &&
                <button
                onClick={preventMouseEvent(this.onCancel.bind(this))}
                className="cancel"
              >
                {this.state.cancelText}
              </button>}
            </div>
          </div>
        </div>
      </OverlayComponent>
    );
  }
}
