import axios from 'axios';
import {
  BannerDto,
  BookingDataTypeCanUploadFile,
  CreateNewBannerRequestBodyDto,
  FilterQuery,
  ListBannerResponseDto,
  UpdateBannerRequestBodyDto,
  UploadUrlDto,
} from '../definition';

import { ENV } from '../initialization';
import { putToS3 } from '../utils';
const DOMAIN = ENV.BACKEND_PREFIX_URL;

// const DOMAIN = 'http://localhost:9090';

export const uploadBannerImage = async (
  imageFile: File,
  serviceFileId?: string,
) => {
  const url = `${DOMAIN}/api/booking/get-upload-url`;
  const getUrlResponse = await axios.post<UploadUrlDto>(url, {
    fileName: imageFile.name,
    fileId: serviceFileId,
    dataType: BookingDataTypeCanUploadFile.service_image,
  });
  await putToS3(imageFile, getUrlResponse.data.preSignUrl);
  return getUrlResponse.data;
};

export const asyncCreateBanner = async (
  data: CreateNewBannerRequestBodyDto,
): Promise<void> => {
  await axios.post(`${DOMAIN}/api/booking/banner/create`, data);
};

export const asyncUpdateBanner = async (
  id: string,
  data: UpdateBannerRequestBodyDto,
): Promise<void> => {
  await axios.put(`${DOMAIN}/api/booking/banner/save/${id}`, data);
};

export const asyncDeleteBanner = async (id: string): Promise<void> => {
  await axios.delete(`${DOMAIN}/api/booking/banner/delete/${id}`);
};

export const asyncGetBanner = async (id: string): Promise<BannerDto> => {
  const response = await axios.get<BannerDto>(
    `${DOMAIN}/api/booking/banner/detail/${id}`,
  );
  return response.data;
};
export const asyncGetBanners = async (
  filter?: FilterQuery,
): Promise<ListBannerResponseDto> => {
  const url = `${DOMAIN}/api/booking/banner/list`;
  const response = await axios.get<ListBannerResponseDto>(url, {
    params: filter,
  });
  return response.data;
};
