import React from 'react';

export const BookingDashboardPage = React.lazy(
  () => import('./booking-dashboard')
);

export const NewOneTimeBookingPage = React.lazy(
  () => import('./new-one-time-booking')
);

export const NewContractBookingPage = React.lazy(
  () => import('./new-contract-booking')
);
