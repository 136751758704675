import axios from 'axios';
import {
  ListTimeSlotResponseDto,
  ScheduleCalendarConfigurationType,
  TimeSlotDto,
} from '../definition/time-slot.dto';
import { CurrentCompanyInfoInfoResponseDto } from '../definition';
import { ENV } from '../initialization';
const DOMAIN = ENV.BACKEND_PREFIX_URL;

const defaultDayConfig: Omit<TimeSlotDto, 'name'> = {
  enable: false,
  sessions: [],
  slotsPerSession: 0,
};

export const asyncGetAppConfig = async () => {
  const response = await axios.get<ListTimeSlotResponseDto>(
    `${DOMAIN}/api/booking/app-config/time-slot`,
  );
  const timeSlots = response.data.items;
  const configuration: ScheduleCalendarConfigurationType = {
    Monday: {
      name: 'Monday',
      ...defaultDayConfig,
      ...timeSlots.find((item) => item.name === 'Monday'),
    },
    Tuesday: {
      name: 'Tuesday',
      ...defaultDayConfig,
      ...timeSlots.find((item) => item.name === 'Tuesday'),
    },
    Wednesday: {
      name: 'Wednesday',
      ...defaultDayConfig,
      ...timeSlots.find((item) => item.name === 'Wednesday'),
    },
    Thursday: {
      name: 'Thursday',
      ...defaultDayConfig,
      ...timeSlots.find((item) => item.name === 'Thursday'),
    },
    Friday: {
      name: 'Friday',
      ...defaultDayConfig,
      ...timeSlots.find((item) => item.name === 'Friday'),
    },
    Saturday: {
      name: 'Saturday',
      ...defaultDayConfig,
      ...timeSlots.find((item) => item.name === 'Saturday'),
    },
    Sunday: {
      name: 'Sunday',
      ...defaultDayConfig,
      ...timeSlots.find((item) => item.name === 'Sunday'),
    },
  };
  return configuration;
};

export const asyncSaveAppConfig = async (timeSlots: TimeSlotDto[]) => {
  await axios.put(`${DOMAIN}/api/booking/app-config/time-slot`, {
    items: timeSlots,
  });
};

export const asyncGetCompanyInfor = async () => {
  const response = await axios.get(`${DOMAIN}/api/booking/app-config/company-info`);
  return response.data
};

export const asyncSaveCompanyInfor = async (companyInfor: CurrentCompanyInfoInfoResponseDto) => {
 await axios.put(`${DOMAIN}/api/booking/app-config/company-info`, companyInfor);
};
