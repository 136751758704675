import { FilterQuery } from './_.dto';

export interface FeedbackDto {
  id: string;
  userId: string;
  userName: string;
  avatarUrl?: string;
  createdAt: Date;
  rating: number;
  comment: string;
  serviceId: string;
  serviceName: string;
  approved: boolean;
  improvement: boolean;
}

export interface ListFeedbackResponseDto {
  items: FeedbackDto[];
  count: number;
}

export enum SearchType {
  REVIEW = 'review', // All reviews, except improvement ones
  IMPROVEMENT = 'improvement', // Only improvement ones
}

export interface FeedbackFilterQuery extends FilterQuery {
  type: SearchType;
  withoutDeclined?: boolean;
}
