import axios from 'axios';
import { FeedbackDto, FeedbackFilterQuery, ListFeedbackResponseDto } from '../definition/feedback.dto';
import { ENV } from '../initialization';

const DOMAIN = ENV.BACKEND_PREFIX_URL;

export const asyncGetListFeedbacks = async (
  data: FeedbackFilterQuery
): Promise<ListFeedbackResponseDto | undefined> => {
  const url = `${DOMAIN}/api/booking/feedback`;
  const response = await axios.get<ListFeedbackResponseDto>(url, {
    params: data,
  });
  return response.data;
};

export const asyncGetFeedbackDetail = async (
  id: string
): Promise<FeedbackDto | undefined> => {
  const url = `${DOMAIN}/api/booking/feedback/${id}`;
  const response = await axios.get(url);
  return response.data;
};

export const asyncApproveFeedback = async (
  id: string
): Promise<void> => {
  const url = `${DOMAIN}/api/booking/feedback/approve/${id}`;
  await axios.post(url);
};

export const asyncImproveFeedback = async (
  id: string
): Promise<void> => {
  const url = `${DOMAIN}/api/booking/feedback/improve/${id}`;
  await axios.post(url);
};

export const asyncDeclineFeedback = async (
  id: string
): Promise<void> => {
  const url = `${DOMAIN}/api/booking/feedback/decline/${id}`;
  await axios.post(url);
};

export const asyncDeleteServiceFeedback = async (id: string): Promise<void> => {
  const url = `${DOMAIN}/api/booking/feedback/${id}`;
  await axios.delete(url);
};
