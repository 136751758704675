import { AccountInfoDto } from './account.dto';
import { BookingDto, BOOKING_TYPE, TaskDto } from './booking.dto';
import { DISCOUNT_TYPE } from './promo.dto';
import { QuoteDto } from './quote.dto';
import { ServiceDto } from './service.dto';

export enum PaymentStatus {
  TO_PAY = 'TO_PAY',
  PENDING = 'PENDING',
  PAID = 'PAID',
  CANCEL = 'CANCEL'
}

export enum PromoteCodeStatus {
  ON_GOING = 'ON_GOING',
  EXPIRED = 'EXPIRED',
}

export enum PaymentMethod {
  CASH = 'CASH',
  PAYNOW = 'PAYNOW',
  BANK = 'BANK',
}

export enum PaymentTerm {
  COD = 'COD',
  CREDIT = 'CREDIT',
  PPP = 'PPP',
  FPP = 'FPP',
  OTHER = 'OTHER'
}

export enum PaymentTermLabel {
  COD = 'Cash on Delivery',
  CREDIT = 'Credit Terms',
  PPP = 'Partial Pre-payment',
  FPP = 'Full Pre-payment',
  OTHER = 'Others'
}

export enum SortInvoiceField {
  AMOUNT = 'amount',
  PAYMENT_STATUS = 'paymentStatus',
  CREATE_AT = 'createdAt',
  INVOICE_NO = 'invoiceNo',
  CUSTOMER_NAME = 'customerName',
  SERVICE_NAME = 'serviceName',
  PAYMENT_METHOD = 'paymentMethod',
  TECHNICIAN = 'technician'
}

export interface InvoiceDto {
  id: string;
  code: string;
  accountId: string;
  account?: AccountInfoDto;
  paymentStatus: PaymentStatus;
  paymentMethod: PaymentMethod;
  paymentMethodPre: PaymentMethod;
  bookingId: string;
  booking?: BookingDto;
  quoteId: string;
  quote?: QuoteDto;
  createdAt: Date;
  voInvoices?: VOInvoiceDto[];
  paidImage?: string;
  paidImageUrl?: string;
  paymentTerm?: string;
  paymentTermValue?: number;

  verifierId?: string;
  verifier?: AccountInfoDto;
  verifiedAt?: Date;
}

export interface InvoiceFilterQuery {
  offset?: number;
  limit?: number;
  search?: string;
  paymentStatus?: PaymentStatus;
  bookingType?: BOOKING_TYPE;
  paymentMethod?: string;
  serviceIds?: string;
  paymentTerms?: string;
  fromDate?: string;
  toDate?: string;
  technicianIds?: string;
  export?: boolean;
  sortBy?: string;
  sortType?: string;
  amount?: string;  
}

export interface ListInvoiceResponseDto {
  items: InvoiceDto[];
  count: number;
}

export interface VOInvoiceServiceDto {
  voInvoiceId: string;
  // voInvoice?: VOInvoiceDto;
  serviceId: string;
  service?: ServiceDto;
  amount: number;
  quantity: number;
  discount?: number;
  discountType?: DISCOUNT_TYPE;
}

export interface VOInvoiceDto {
  id: string;
  code: string;
  mainInvoiceId: string;
  // mainInvoice?: InvoiceDto;
  paymentStatus: PaymentStatus;
  paymentMethod?: PaymentMethod;
  verifierId?: string;
  verifier?: AccountInfoDto;
  verifiedAt?: Date;
  paidImage?: string;
  paidImageUrl?: string;
  creatorId?: string;
  creator?: AccountInfoDto;
  taskId?: string;
  task?: TaskDto;
  voServices: VOInvoiceServiceDto[];
  createdAt: Date;
}

export interface VOServiceRequestBodyDto {
  serviceId: string;
  amount: number;
  quantity: number;
  discount?: number;
}

export interface VOTaskRequestBodyDto {
  taskId: string;
  voServices: VOServiceRequestBodyDto[];
}

export interface AdminCreateVOInvoiceRequestBodyDto {
  invoiceId: string;
  voTasks: VOTaskRequestBodyDto[];
}

export interface AdminConfirmPaymentDto {
  paymentMethod?: PaymentMethod;
  paidImage?: string;
}

export interface AdminUpdatePaymentTermDto {
  paymentTerm?: string;
  paymentTermValue?: number;
}

export interface AdminUpdatePrePaymentMethodDto {
  paymentMethod?: PaymentMethod | string;
}
