import { Component, PropsWithChildren, ReactNode } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AuthenticationData } from '../definition';
import { LayoutComponent } from './components';
import {
  CustomerListPage,
  ForgotPasswordPage,
  LoginPage,
  NotFoundPage,
  ResetPasswordPage,
  ServiceListPage,
  SettingPage,
  TechnicianListPage,
} from './pages';
import { BannerListPage } from './pages/management/banner';
import {
  NewContractBookingPage,
  NewOneTimeBookingPage,
} from './pages/management/booking';
import { NewBookingType } from './pages/management/booking/select-new-booking-type';
import { DashboardPage } from './pages/management/dashboard';
import { FeedbackPage } from './pages/management/feedback';
import {
  FinanceReportPage,
  TermGSTPage,
} from './pages/management/finance-report';
import { InvoiceDetailPage } from './pages/management/invoice';
import { NotificationPage } from './pages/management/notification';
import { SchedulePage } from './pages/management/schedule';
import { TaskPage } from './pages/management/task';

export class EmptyPage extends Component {
  render() {
    return <div></div>;
  }
}

export class LoggedInRouter extends Component<any & PropsWithChildren> {
  render(): ReactNode {
    if (!this.props.accountInfo?.id) {
      // user is not authenticated
      return <Navigate to="/login" />;
    }
    return this.props.children;
  }
}

export class NotLoggedInRouter extends Component<any & PropsWithChildren> {
  render(): ReactNode {
    if (this.props.accountInfo?.id) {
      // user is not authenticated
      return <Navigate to="/" />;
    }
    return this.props.children;
  }
}

export const AppRouter = ({
  accountInfo,
  configuration,
  companyInfo
}: AuthenticationData) => (
  <Routes>
    <Route
      element={
        <LayoutComponent
          accountInfo={accountInfo}
          configuration={configuration}
          companyInfo={companyInfo}
        />
      }
    >
      <Route
        path="/login"
        element={
          <NotLoggedInRouter accountInfo={accountInfo}>
            <LoginPage />
          </NotLoggedInRouter>
        }
      />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route
        path="/"
        element={
          <LoggedInRouter accountInfo={accountInfo}>
            <DashboardPage />
          </LoggedInRouter>
        }
      />

      <Route
        path="/customers"
        element={
          <LoggedInRouter accountInfo={accountInfo}>
            <CustomerListPage />
          </LoggedInRouter>
        }
      />
      <Route
        path="/technician"
        element={
          <LoggedInRouter accountInfo={accountInfo}>
            <TechnicianListPage />
          </LoggedInRouter>
        }
      />
      <Route
        path="/service"
        element={
          <LoggedInRouter accountInfo={accountInfo}>
            <ServiceListPage />
          </LoggedInRouter>
        }
      />
      <Route
        path="/banner"
        element={
          <LoggedInRouter accountInfo={accountInfo}>
            <BannerListPage />
          </LoggedInRouter>
        }
      />
      <Route
        path="/setting"
        element={
          <LoggedInRouter accountInfo={accountInfo} companyInfo={companyInfo}>
            <SettingPage />
          </LoggedInRouter>
        }
      />
      <Route
        path="/feedback"
        element={
          <LoggedInRouter accountInfo={accountInfo}>
            <FeedbackPage />
          </LoggedInRouter>
        }
      />
      <Route
        path="/notification"
        element={
          <LoggedInRouter accountInfo={accountInfo}>
            <NotificationPage />
          </LoggedInRouter>
        }
      />
      <Route
        path="/schedule"
        element={
          <LoggedInRouter accountInfo={accountInfo}>
            <SchedulePage />
          </LoggedInRouter>
        }
      />
      <Route
        path="/schedule/booking-type"
        element={
          <LoggedInRouter accountInfo={accountInfo}>
            <NewBookingType />
          </LoggedInRouter>
        }
      />
      <Route
        path="/schedule/one-time-booking"
        element={
          <LoggedInRouter accountInfo={accountInfo}>
            <NewOneTimeBookingPage />
          </LoggedInRouter>
        }
      />
      <Route
        path="/schedule/contract-booking"
        element={
          <LoggedInRouter accountInfo={accountInfo}>
            <NewContractBookingPage />
          </LoggedInRouter>
        }
      />
      <Route
        path="/finance-report"
        element={
          <LoggedInRouter accountInfo={accountInfo}>
            <FinanceReportPage />
          </LoggedInRouter>
        }
      />
      <Route
        path="/finance-report/term-gst"
        element={
          <LoggedInRouter accountInfo={accountInfo}>
            <TermGSTPage />
          </LoggedInRouter>
        }
      />
      <Route
        path="/schedule/task/:id"
        element={
          <LoggedInRouter accountInfo={accountInfo}>
            <TaskPage />
          </LoggedInRouter>
        }
      />
      <Route
        path="/finance-report/invoice/:id"
        element={
          <LoggedInRouter accountInfo={accountInfo}>
            <InvoiceDetailPage />
          </LoggedInRouter>
        }
      />
    </Route>
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);
