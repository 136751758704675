import axios from 'axios';
import {
  AccountInfoDto,
  FilterQuery,
  ListAccountInfoResponseDto,
  NewCustomerRequestBodyDto,
  UpdateCustomerRequestBodyDto,
  UploadUrlDto,
} from '../definition';
import { ENV } from '../initialization';
import { putToS3 } from '../utils';

const DOMAIN = ENV.BACKEND_PREFIX_URL;

export const asyncGetListCustomers = async (
  data: FilterQuery,
): Promise<ListAccountInfoResponseDto | undefined> => {
  const url = `${DOMAIN}/api/authentication/profile/customer/list`;
  const response = await axios.get<ListAccountInfoResponseDto>(url, {
    params: data,
  });
  return response.data;
};

export const uploadAvatar = async (
  avatarFile: File,
  customerId?: string,
  avatarFileId?: string,
) => {
  const url = `${DOMAIN}/api/authentication/profile/get-upload-url`;
  const getUrlResponse = await axios.post<UploadUrlDto>(url, {
    fileName: avatarFile.name,
    fileId: avatarFileId,
    ownerId: customerId,
    dataType: 'avatar',
  });
  await putToS3(avatarFile, getUrlResponse.data.preSignUrl);
  return getUrlResponse.data;
};

export const asyncCreateCustomer = async (
  data: NewCustomerRequestBodyDto,
): Promise<AccountInfoDto | undefined> => {
  const url = `${DOMAIN}/api/authentication/profile/customer/create`;
  const rtData = await axios.post(url, data);
  return rtData.data;
};

export const asyncGetCustomerDetail = async (
  id: string,
): Promise<AccountInfoDto | undefined> => {
  const url = `${DOMAIN}/api/authentication/profile/customer/detail/${id}`;
  const response = await axios.get(url);
  return response.data;
};

export const asyncSaveCustomer = async (
  id: string,
  data: UpdateCustomerRequestBodyDto,
): Promise<void> => {
  const url = `${DOMAIN}/api/authentication/profile/customer/save/${id}`;
  await axios.post(url, data);
};

export const asyncDeleteCustomer = async (id: string): Promise<void> => {
  const url = `${DOMAIN}/api/authentication/profile/customer/delete/${id}`;
  await axios.delete(url);
};
