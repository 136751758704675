import axios from 'axios';
import { TaskDto, UpdateTaskRequestBodyDto } from '../definition/booking.dto';
import { ENV } from '../initialization';

const DOMAIN = ENV.BACKEND_PREFIX_URL;

export const asyncGetTaskDetail = async (id: string) => {
  const url = `${DOMAIN}/api/booking/task/detail/${id}`;
  const response = await axios.get<TaskDto>(url);
  return response.data;
};

export const asyncSaveTask = async (data: UpdateTaskRequestBodyDto) => {
  const url = `${DOMAIN}/api/booking/task/update`;
  const response = await axios.put<TaskDto>(url, data);
  return response.data;
};
