import { ServiceDto } from './service.dto';

export enum BannerStatus {
  UNPUBLISHED = 'UNPUBLISHED',
  PUBLISHED = 'PUBLISHED',
}
export interface BannerDto {
  id: string;

  bannerStatus: BannerStatus;

  bannerImage: string;
  bannerImageUrl: string;

  serviceId: string;

  service?: ServiceDto;
}

export interface ListBannerResponseDto {
  items: BannerDto[];

  count: number;
}

export interface CreateNewBannerRequestBodyDto {
  bannerStatus: BannerStatus;
  bannerImage: string;
  serviceId: string;
}

export interface UpdateBannerRequestBodyDto {
  bannerStatus: BannerStatus;

  bannerImage: string;

  bannerImageIsTemporary?: boolean;

  serviceId: string;
}
