import axios from 'axios';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { APP_EVENTS, JWT_TOKEN } from '../constants';
import { asyncGetAppConfig, asyncGetCompanyInfor } from '../services';
import { asyncGetCurrentAccountInformation } from '../services/account.services';
import { MyEventEmitter } from '../utils';

export const ENV = {
  BACKEND_PREFIX_URL: process.env.REACT_APP_BACKEND_PREFIX_URL,
};

export const appEventEmitter = new MyEventEmitter();

export const initialization = async () => {
  axios.interceptors.request.use((config) => {
    const jwt = window.localStorage.getItem(JWT_TOKEN);
    config.headers = {
      ...config.headers,
      utcOffset: moment().utcOffset(),
      authorization:
        jwt && !config.headers?.notAddAuthorization ? `Bearer ${jwt}` : '',
    };
    return config;
  });

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 401) {
        window.localStorage.removeItem(JWT_TOKEN);
        useNavigate()('/login');
      } else {
        return Promise.reject(error);
      }
    },
  );

  return await Promise.all([
    asyncGetCurrentAccountInformation(),
    asyncGetAppConfig(),
    asyncGetCompanyInfor()
  ]);
};

export const processLoading = <T,>(
  promise: Promise<T>,
  tms?: number,
): Promise<T> => {
  return new Promise<T>((resolve, reject) => {
    const startTime = Date.now();
    appEventEmitter.emit(APP_EVENTS.app_loading, {
      display: true,
    });
    promise
      .then((value) => {
        setTimeout(() => {
          appEventEmitter.emit(APP_EVENTS.app_loading, {
            display: false,
          });
          resolve(value);
        }, startTime + (tms || 100) - Date.now());
      })
      .catch((error) => {
        setTimeout(() => {
          appEventEmitter.emit(APP_EVENTS.app_loading, {
            display: false,
          });
          reject(error);
        }, startTime + (tms || 100) - Date.now());
      });
  });
};
