import circleRight from './../../../../styles/images/circle-arrow-right-black.png';
import onetimeIcon from './../../../../styles/images/booking-type-one-time-icon.svg';
import contractIcon from './../../../../styles/images/booking-type-contract-icon.svg';
import { NavLink } from 'react-router-dom';

export function NewBookingType() {
  return (
    <div className="site-page">
      <div className="site-page-header"></div>
      <div className="new-booking-type-body">
        <div className="label">Which booking are you creating?</div>

        <div className="wrapper">
          <img alt="one-time-booking" src={onetimeIcon} className="left-icon" />
          <div className="separator"></div>
          <div className="sub-label">One-Time Booking</div>
          <NavLink to="/schedule/one-time-booking">
            <img alt="avatar" src={circleRight} className="right-icon" />
          </NavLink>
        </div>
        <div className="wrapper">
          <img alt="avatar" src={contractIcon} className="left-icon" />
          <div className="separator"></div>
          <div className="sub-label">Contract Booking</div>
          <NavLink to="/schedule/contract-booking">
            <img alt="avatar" src={circleRight} className="right-icon" />
          </NavLink>
        </div>
      </div>
    </div>
  );
}
