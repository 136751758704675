import queryString from 'query-string';

type AddUrlParams = { [key: string]: string };

export const getQueryParams = <T,>(): T => {
  return queryString.parse(window.location.search) as any;
};

const pushUrlParams = (searchParams: URLSearchParams) => {
  let paramsString = searchParams.toString();
  if (paramsString) {
    paramsString = '?' + paramsString;
  }
  let newUrl =
    window.location.protocol +
    '//' +
    window.location.host +
    window.location.pathname +
    paramsString;
  window.history.pushState({ path: newUrl }, '', newUrl);
};

export const addUrlParam = (key: string, value: string) => {
  let searchParams = new URLSearchParams(window.location.search);
  searchParams.set(key, value);
  pushUrlParams(searchParams);
};

export const deleteUrlParam = (key: string) => {
  let searchParams = new URLSearchParams(window.location.search);
  searchParams.delete(key);
  pushUrlParams(searchParams);
};

export const updateUrlParam = (data: {
  add?: AddUrlParams;
  delete?: string[];
}) => {
  let searchParams = new URLSearchParams(window.location.search);
  for (let key in data.add) {
    searchParams.set(key, data.add[key]);
  }
  for (let key of data.delete || []) {
    searchParams.delete(key);
  }
  pushUrlParams(searchParams);
};
