export * from './booking.config'

const JWT_TOKEN = 'jwt-token';
export { JWT_TOKEN };

export const APP_EVENTS = {
  app_loading: 'app-loading',
  app_modal: 'app-modal',
  app_confirm_modal: 'app-confirm-modal',
};

export const DATE_FORMAT_DDMMMyyyy = 'DD MMM yyyy';
export const TIME_FORMAT_hhmmA = 'hh.mmA';
export const DATE_FORMAT_ISO = 'YYYY-MM-DDTHH:mm:ss';

export const DEFAULT_TOPIC_FIELDS = [
  'Technician Assigned',
  'Attach Job Order',
  'Add Invoice',
];

export const SWITCH_FUNCTION = {
  country_code: false,
  employee_role: true,
  service_charge: true,
  promote_code: true,
  pdf_ver2: true,
  config_price: true,
  onetime_notes: true,
  delete_review: true,
  tech_cus_required_only_contact: false,
  timeframe: false,
  remove_COD: false,
  hierachy_category: true,
  surcharge: true,
}
